import React, { useEffect, useState } from 'react';
import { editarCliente, obterClientePorId } from '../services/api';
import CadastroCliente from './CadastroCliente';
import { useParams, useNavigate } from 'react-router-dom';

const EditarCliente = ({ token }) => {
  const [initialData, setInitialData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await obterClientePorId(id, token);
        setInitialData(response.data);
      } catch (error) {
        console.error('Erro ao carregar os dados do cliente:', error);
      }
    };
    fetchData();
  }, [id, token]);

  const handleEditSubmit = async (data, token) => {
    try {
      await editarCliente(id, data, token);
      alert('Cliente atualizado com sucesso!');
      navigate('/clientes'); // Navega de volta para a lista de clientes após a edição
    } catch (error) {
      console.error('Erro ao atualizar cliente:', error);
    }
  };

  if (!initialData) {
    return <div>Carregando...</div>;
  }

  return (
    <CadastroCliente 
      token={token} 
      initialData={initialData} 
      onSubmit={handleEditSubmit} 
      isEdit={true} 
    />
  );
};

export default EditarCliente;
