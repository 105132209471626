import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

const CadastroCliente = ({ token, initialData = {}, isEdit = false, onSubmit }) => {
  const [cliente, setCliente] = useState({
    cnpj: '',
    inscricao_estadual: '',
    razao_social: '',
    nome_fantasia: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    cidade: '',
    estado: '',
    email: '',
    telefone: '',
    simples_nacional: '1',
    ...initialData
  });

  useEffect(() => {
    if (isEdit && initialData && Object.keys(initialData).length > 0) {
      setCliente({
        cnpj: initialData.cnpj || '',
        inscricao_estadual: initialData.inscricaoEstadual || '',
        razao_social: initialData.razaoSocial || '',
        nome_fantasia: initialData.nomeFantasia || '',
        logradouro: initialData.logradouro || '',
        numero: initialData.numero || '',
        complemento: initialData.complemento || '',
        bairro: initialData.bairro || '',
        cep: initialData.cep || '',
        cidade: initialData.cidade || '',
        estado: initialData.estado || '',
        email: initialData.email || '',
        telefone: initialData.telefone || '',
        simples_nacional: initialData.simplesNacional ? '1' : '0'
      });
    }
  }, [initialData, isEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCliente({
      ...cliente,
      [name]: name === 'simples_nacional' ? (value === '1') : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const clientePayload = {
      RazaoSocial: cliente.razao_social,
      NomeFantasia: cliente.nome_fantasia,
      CNPJ: cliente.cnpj,
      Logradouro: cliente.logradouro,
      Numero: cliente.numero,
      Bairro: cliente.bairro,
      Cidade: cliente.cidade,
      Estado: cliente.estado,
      CEP: cliente.cep,
      Email: cliente.email,
      Telefone: cliente.telefone,
      InscricaoEstadual: cliente.inscricao_estadual,
      SimplesNacional: cliente.simples_nacional === true,
      Complemento: cliente.complemento || null,
    };

    try {
      await onSubmit(clientePayload, token);
      alert(isEdit ? 'Cliente atualizado com sucesso!' : 'Cliente cadastrado com sucesso!');
    } catch (error) {
      if (error.response) {
        console.error(`Erro ao ${isEdit ? 'atualizar' : 'cadastrar'} cliente:`, error.response.data);
      } else {
        console.error(`Erro ao ${isEdit ? 'atualizar' : 'cadastrar'} cliente`, error);
      }
    }
  };

  const handleBuscarCNPJ = () => {
    let cnpj = cliente.cnpj.replace(/\D/g, '');

    fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Falha ao buscar dados do CNPJ');
        }
        return response.json();
      })
      .then(data => {
        setCliente({
          ...cliente,
          razao_social: data.razao_social || '',
          nome_fantasia: data.estabelecimento.nome_fantasia || '',
          logradouro: data.estabelecimento.logradouro || '',
          numero: data.estabelecimento.numero || '',
          complemento: data.estabelecimento.complemento || '',
          bairro: data.estabelecimento.bairro || '',
          cep: data.estabelecimento.cep || '',
          cidade: data.estabelecimento.cidade.nome || '',
          estado: data.estabelecimento.estado.sigla || '',
          email: data.estabelecimento.email || '',
          telefone: data.estabelecimento.telefone1 || '',
          inscricao_estadual: data.estabelecimento.inscricoes_estaduais.length > 0 
            ? data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual 
            : '',
          simples_nacional: data.simples ? '1' : '0'
        });
      })
      .catch(error => {
        console.error('Erro ao buscar CNPJ:', error);
      });
  };

  return (
    <div className="container mt-5">
      <div className="card">
        <h2 className="d-flex justify-content-center">{isEdit ? 'Editar Cliente' : 'Cadastro de Cliente'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="row mt-5">
            <div className="form-group col-md-6">
              <label htmlFor="cnpj">CNPJ</label>
              <div className="input-group mb-3">
                <InputMask
                  mask="99.999.999/9999-99"
                  type="text"
                  className="form-control"
                  id="cnpj"
                  name="cnpj"
                  value={cliente.cnpj}
                  onChange={handleChange}
                  required
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-success"
                    type="button"
                    onClick={handleBuscarCNPJ}
                  >
                    Buscar
                  </button>
                </div>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inscricao_estadual">Inscrição Estadual</label>
              <input
                type="text"
                className="form-control"
                id="inscricao_estadual"
                name="inscricao_estadual"
                value={cliente.inscricao_estadual}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="razao_social">Razão Social</label>
            <input
              type="text"
              className="form-control"
              id="razao_social"
              name="razao_social"
              value={cliente.razao_social}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="nome_fantasia">Nome Fantasia</label>
            <input
              type="text"
              className="form-control"
              id="nome_fantasia"
              name="nome_fantasia"
              value={cliente.nome_fantasia}
              onChange={handleChange}
              required
            />
          </div>
          <div className="row">
            <div className="form-group col-md-8">
              <label htmlFor="logradouro">Logradouro</label>
              <input
                type="text"
                className="form-control"
                id="logradouro"
                name="logradouro"
                value={cliente.logradouro}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="numero">Número</label>
              <input
                type="text"
                className="form-control"
                id="numero"
                name="numero"
                value={cliente.numero}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="complemento">Complemento</label>
              <input
                type="text"
                className="form-control"
                id="complemento"
                name="complemento"
                value={cliente.complemento}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="bairro">Bairro</label>
              <input
                type="text"
                className="form-control"
                id="bairro"
                name="bairro"
                value={cliente.bairro}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4">
              <label htmlFor="cep">CEP</label>
              <InputMask
                mask="99999-999"
                type="text"
                className="form-control"
                id="cep"
                name="cep"
                value={cliente.cep}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="cidade">Cidade</label>
              <input
                type="text"
                className="form-control"
                id="cidade"
                name="cidade"
                value={cliente.cidade}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="estado">Estado</label>
              <input
                type="text"
                className="form-control"
                id="estado"
                name="estado"
                value={cliente.estado}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={cliente.email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="telefone">Telefone</label>
              <InputMask
                mask="(99) 9999-9999"
                type="text"
                className="form-control"
                id="telefone"
                name="telefone"
                value={cliente.telefone}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="simples_nacional">Simples Nacional</label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="simples_nacional"
                id="simples_nacional1"
                value="1"
                checked={cliente.simples_nacional === '1'}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="simples_nacional1">
                Sim
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="simples_nacional"
                id="simples_nacional0"
                value="0"
                checked={cliente.simples_nacional === '0'}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="simples_nacional0">
                Não
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-success">{isEdit ? 'Salvar' : 'Cadastrar'}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CadastroCliente;
