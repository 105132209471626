import React, { useEffect, useState } from 'react';
import { getQuantidadeClientes, getQuantidadePdvs, getQuantidadeChavesBloqueadas, getQuantidadeChavesAtualizadas } from '../services/api';

const Home = ({ token }) => {
  const [quantidadeClientes, setQuantidadeClientes] = useState(0);
  const [quantidadePdvs, setQuantidadePdvs] = useState(0);
  const [quantidadeChavesBloqueadas, setQuantidadeChavesBloqueadas] = useState(0);
  const [quantidadeChavesAtualizadas, setQuantidadeChavesAtualizadas] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Token:", token); // Verifica o token
  
        const clientesResponse = await getQuantidadeClientes(token);
        setQuantidadeClientes(clientesResponse.data.quantidade);
  
        const pdvsResponse = await getQuantidadePdvs(token);
        setQuantidadePdvs(pdvsResponse.data.quantidade);
  
        const chavesBloqueadasResponse = await getQuantidadeChavesBloqueadas(token);
        setQuantidadeChavesBloqueadas(chavesBloqueadasResponse.data.quantidade);
  
        const chavesAtualizadasResponse = await getQuantidadeChavesAtualizadas(token);
        setQuantidadeChavesAtualizadas(chavesAtualizadasResponse.data.quantidade);
      } catch (error) {
        console.error('Erro ao buscar dados para o dashboard:', error);
      }
    };
    fetchData();
  }, [token]);
  

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Clientes</h5>
              <p className="card-text">{quantidadeClientes}</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">PDVs</h5>
              <p className="card-text">{quantidadePdvs}</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Chaves Bloqueadas</h5>
              <p className="card-text">{quantidadeChavesBloqueadas}</p>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Chaves Atualizadas</h5>
              <p className="card-text">{quantidadeChavesAtualizadas}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
