import React, { useEffect, useState } from 'react';
import { listarClientes, excluirCliente } from '../services/api';
import { useNavigate } from 'react-router-dom';

const ListaClientes = ({ token }) => {
  const [clientes, setClientes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await listarClientes(token);
        setClientes(response.data);
      } catch (error) {
        console.error('Erro ao listar clientes:', error);
      }
    };
    fetchData();
  }, [token]);

  const handleEditar = (id) => {
    navigate(`/clientes/editar/${id}`);
  };

  const handleExcluir = async (id) => {
    try {
      await excluirCliente(id, token);
      setClientes(clientes.filter(cliente => cliente.id !== id));
      alert('Cliente excluído com sucesso!');
    } catch (error) {
      console.error('Erro ao excluir cliente:', error);
      alert('Erro ao excluir cliente!');
    }
  };

  return (
    <div className="container mt-5">
      <h2>Listagem de Clientes</h2>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>CNPJ</th>
            <th>Razão Social</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {clientes.map((cliente) => (
            <tr key={cliente.id}>
              <td>{cliente.cnpj}</td>
              <td>{cliente.razaoSocial}</td>
              <td>
                <button 
                  className="btn btn-primary" 
                  onClick={() => handleEditar(cliente.id)}
                >
                  Editar
                </button>
                <button 
                  className="btn btn-danger" 
                  onClick={() => handleExcluir(cliente.id)}
                >
                  Excluir
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaClientes;
