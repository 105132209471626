import React from 'react';

const Navbar = ({ userName, onLogout }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="/home">
          <img src="/logo.png" alt="Logo" width="260" height="100" />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse d-flex justify-content-between" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="licenciamentoDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Licenciamento
              </a>
              <ul className="dropdown-menu" aria-labelledby="licenciamentoDropdown">
                <li><a className="dropdown-item" href="/pdvs/novo">Novo PDV</a></li>
                <li><a className="dropdown-item" href="/pdvs/gerar-chave">Gerar Chave</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="clientesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Clientes
              </a>
              <ul className="dropdown-menu" aria-labelledby="clientesDropdown">
                <li><a className="dropdown-item" href="/clientes">Listar Clientes</a></li>
                <li><a className="dropdown-item" href="/clientes/novo">Novo Cliente</a></li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="usuariosDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Usuários
              </a>
              <ul className="dropdown-menu" aria-labelledby="usuariosDropdown">
                <li><a className="dropdown-item" href="/usuarios">Listar Usuários</a></li>
                <li><a className="dropdown-item" href="/usuarios/novo">Novo Usuário</a></li>
              </ul>
            </li>
          </ul>
          <ul className="navbar-nav d-flex align-items-center">
            <li className="nav-item">
              <span className="nav-link">Olá, {userName || 'Usuário'}</span>
            </li>
            <li className="nav-item">
              <button className="btn btn-outline-danger ml-2" onClick={onLogout}>Sair</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
