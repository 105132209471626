import React from 'react';
import CadastroPdv from './CadastroPdv';
import { cadastrarPdv } from '../services/api';

const NovoPDV = ({ token }) => {
  const handleSubmit = async (data) => {
    try {
      await cadastrarPdv(data, token);
      alert('PDV cadastrado com sucesso!');
    } catch (error) {
      console.error('Erro ao cadastrar PDV:', error);
      alert('Erro ao cadastrar PDV.');
    }
  };

  return (
    <CadastroPdv onSubmit={handleSubmit} token={token} />
  );
};

export default NovoPDV;
