import React, { useEffect, useState } from 'react';
import { editarPdv, obterPdvPorId } from '../services/api';
import CadastroPdv from './CadastroPdv';
import { useParams, useNavigate } from 'react-router-dom';  // Importe useNavigate

const EditarPdv = ({ token }) => {
  const [initialData, setInitialData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();  // Inicialize o navigate

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await obterPdvPorId(id, token);
        setInitialData(response.data);
      } catch (error) {
        console.error('Erro ao carregar os dados do PDV:', error);
      }
    };
    fetchData();
  }, [id, token]);

  const handleEditSubmit = async (data, token) => {
    try {
      await editarPdv(id, data, token);
      alert('PDV atualizado com sucesso!');
      navigate('/pdvs/gerar-chave');  // Use navigate para redirecionar após a atualização
    } catch (error) {
      console.error('Erro ao atualizar PDV:', error);
    }
  };

  if (!initialData) {
    return <div>Carregando...</div>;
  }

  return (
    <CadastroPdv 
      token={token} 
      initialData={initialData} 
      onSubmit={handleEditSubmit} 
      isEdit={true} 
    />
  );
};

export default EditarPdv;
