import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Autenticação
export const login = (data) => api.post('/account/login', data);

// Clientes
export const cadastrarCliente = (data, token) => 
  api.post('/clientes', data, { headers: { Authorization: `Bearer ${token}` } });

export const listarClientes = (token) =>
  api.get('/clientes', { headers: { Authorization: `Bearer ${token}` } });

export const obterClientePorId = (id, token) => 
  api.get(`/clientes/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const editarCliente = (id, data, token) =>
  api.put(`/clientes/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });

export const excluirCliente = (id, token) =>
  api.delete(`/clientes/${id}`, { headers: { Authorization: `Bearer ${token}` } });

// PDVs
export const listarPdvs = (token) =>
  api.get('/pdvs', { headers: { Authorization: `Bearer ${token}` } });

export const gerarLicenca = (id, data, token) =>
  api.put(`/pdvs/gerar-chave/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });

export const baixarChave = (id, token) =>
  api.get(`/pdvs/${id}/baixar-chave`, { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' });

export const cadastrarPdv = (data, token) => 
  api.post('/pdvs', data, { headers: { Authorization: `Bearer ${token}` } });

export const excluirPdv = (id, token) =>
  api.delete(`/pdvs/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const obterPdvPorId = (id, token) =>
  api.get(`/pdvs/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const editarPdv = (id, data, token) =>
  api.put(`/pdvs/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });


// Usuários
export const listarUsuarios = (token) =>
  api.get('/usuarios', { headers: { Authorization: `Bearer ${token}` } });

export const obterUsuarioPorId = (id, token) =>
  api.get(`/usuarios/${id}`, { headers: { Authorization: `Bearer ${token}` } });

export const cadastrarUsuario = (data, token) =>
  api.post('/usuarios', data, { headers: { Authorization: `Bearer ${token}` } });

export const editarUsuario = (id, data, token) =>
  api.put(`/usuarios/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });

export const excluirUsuario = (id, token) =>
  api.delete(`/usuarios/${id}`, { headers: { Authorization: `Bearer ${token}` } });

// Home

export const getQuantidadeClientes = (token) => {
  return api.get('/clientes/quantidade', { headers: { Authorization: `Bearer ${token}` } });
};

export const getQuantidadePdvs = (token) => {
  return api.get('/pdvs/quantidade', { headers: { Authorization: `Bearer ${token}` } });
};

export const getQuantidadeChavesBloqueadas = (token) => {
  return api.get('/pdvs/quantidade-chaves-bloqueadas', { headers: { Authorization: `Bearer ${token}` } });
};

export const getQuantidadeChavesAtualizadas = (token) => {
  return api.get('/pdvs/quantidade-chaves-atualizadas', { headers: { Authorization: `Bearer ${token}` } });
};


export default api;
