import React, { useEffect, useState } from 'react';
import { editarUsuario, obterUsuarioPorId } from '../services/api';
import CadastroUsuario from './CadastroUsuario';
import { useParams } from 'react-router-dom';

const EditarUsuario = ({ token }) => {
  const [initialData, setInitialData] = useState(null); // Inicialmente null para diferenciar entre dados carregados e não carregados
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await obterUsuarioPorId(id, token);
        setInitialData(response.data); // Defina o estado com os dados recebidos
      } catch (error) {
        console.error('Erro ao carregar os dados do usuário:', error);
      }
    };
    fetchData();
  }, [id, token]);

  const handleEditSubmit = async (data, token) => {
    try {
      await editarUsuario(id, data, token);
      alert('Usuário atualizado com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
    }
  };

  if (!initialData) {
    return <div>Carregando...</div>; // Exibe "Carregando..." enquanto os dados não são carregados
  }

  return (
    <CadastroUsuario 
      token={token} 
      initialData={initialData} 
      onSubmit={handleEditSubmit} 
      isEdit={true} 
    />
  );
};

export default EditarUsuario;
